<template>
  <div class="com-product-StoreNumber">
    <div class="title">门店数量</div>
    <div class="input-panel">
      <span @click="subtractClick" :class="{ disabled: $attrs['value'] == min }"
        >-</span
      >
      <a-input-number
        v-bind="$attrs"
        @change="setValue"
        :precision="0"
        :min="min"
        :max="max"
      />
      <span @click="plusClick" :class="{ disabled: $attrs['value'] == max }"
        >+</span
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      min: 1,
      max: 1000,
    };
  },
  methods: {
    setValue(value) {
      const { min, max } = this;
      if (isNaN(value)) value = 1;
      if (value < min) value = min;
      if (value > max) value = max;
      this.$emit("input", value);
      this.$emit("change", value);
    },
    subtractClick() {
      let { value } = this.$attrs;
      if (value === this.min) return;
      this.setValue(value - 1);
    },
    plusClick() {
      const { value } = this.$attrs;
      if (value === this.max) return;
      this.setValue(value + 1);
    },
  },
};
</script>
<style lang="less" scoped>
.com-product-StoreNumber {
  display: flex;
  padding: 10px 0;
  .title {
    width: 100px;
    height: 34px;
    line-height: 34px;
  }
  .input-panel {
    width: 158px;
    height: 34px;
    border: 1px solid #e2e7f0;
    text-align: center;
    span {
      width: 33px;
      display: inline-block;
      cursor: pointer;
      user-select: none;
      font-size: 20px;
      line-height: 20px;
      color: black;
      &.disabled {
        color: #aeaeae;
      }
    }
    /deep/ .ant-input-number {
      border-radius: 0;
      border-top: none;
      border-bottom: none;
      border-color: #e2e7f0;
      box-shadow: none;
      outline: none;
      .ant-input-number-input {
        text-align: center;
        color: black;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}
</style>